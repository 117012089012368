$(function () {
  const headerHight = 60;

  $('a[href^="#"]').on('click', function(event) {
    event.preventDefault();
    const target = $(this.hash);
    const targetOffset = target.offset().top - headerHight;
    $('html, body').animate({ scrollTop: targetOffset }, 1000);
  });
});


$(function () {
  const canvas = document.querySelector(".canvas-bg");
  const context = canvas.getContext("2d");

  const dpr = window.devicePixelRatio || 1;

  let width = (canvas.width = window.innerWidth * dpr);
  let height = (canvas.height = window.innerHeight * dpr);

  canvas.style.width = window.innerWidth + "px";
  canvas.style.height = window.innerHeight + "px";

  const numLines = 5; // 線の本数
  const numPoints = 2000; // 線の点の数
  const amplitude = height / 2; // 線の振幅（波の高さ）
  const phaseOffsets = []; // 線ごとの位相オフセット
  const phaseIncrements = []; // 線ごとの位相増分
  const lineWidth = 2 * dpr; // 線の幅
  const lineColors = [
    "rgba(50, 115, 220, 0.3)",
    "rgba(73, 144, 247, 0.3)",
    "rgba(255, 221, 87, 0.3)",
    "rgba(74, 226, 150, 0.3)",
    "rgba(255, 120, 91, 0.3)"
  ];

  const maxHeight = height * 3; // 最大の高さ

  let startingPositions;

  function update() {
    width = window.innerWidth * dpr;
    height = window.innerHeight * dpr;

    canvas.width = width;
    canvas.height = height;

    context.clearRect(0, 0, width, height);

    // ウィンドウの幅が1070px以下の場合に開始位置を変更
    if (window.innerWidth <= 1070) {
      startingPositions = [
        height * 0.400,
        height * 0.510,
        height * 0.660,
        height * 0.750,
        height * 0.890
      ];
    } else {
      startingPositions = [
        height * 0.285,
        height * 0.395,
        height * 0.470,
        height * 0.620,
        height * 0.660
      ];
    }

    for (let j = 0; j < numLines; j++) {
      const points = [];
      let phase = phaseOffsets[j];

      for (let i = 0; i < numPoints; i++) {
        const x = (width / (numPoints - 1)) * i;
        let y =
          startingPositions[j] -
          Math.sin(phase) * amplitude * (1 - i / (numPoints - 1));
        y = Math.min(y, maxHeight); // 最大高さまでの制限
        points.push({ x: x, y: y });
        phase += phaseIncrements[j];
      }

      context.beginPath();
      context.moveTo(points[0].x, points[0].y);

      for (let i = 1; i < numPoints; i++) {
        context.lineTo(points[i].x, points[i].y);
      }

      context.lineWidth = lineWidth;
      context.strokeStyle = lineColors[j % lineColors.length];
      context.stroke();
    }

    for (let j = 0; j < numLines; j++) {
      phaseOffsets[j] += phaseIncrements[j];
    }

    requestAnimationFrame(update);
  }

  // 位相オフセットと位相増分を初期化
  for (let i = 0; i < numLines; i++) {
    phaseOffsets.push(Math.random() * Math.PI * 2);
    phaseIncrements.push(0.0005);
  }

  // スマートフォンの画面サイズ変更時に再描画する
  window.addEventListener("resize", function() {
    clearTimeout(window.resizedFinished);
    window.resizedFinished = setTimeout(function() {
      update();
    }, 250);
  });

  update();
});


$(window).on('scroll', function () {
  const scroll = $(window).scrollTop();
  const windowHeight = $(window).height();
  
  $(".js-border-slide").each(function (index) {
    const position = $(this).offset().top;

    if (scroll > position - windowHeight) {
      $(this).addClass('is-active');

      if (index === 1) {
        setTimeout(function () {
          $('.js-label-display').addClass('is-active');
        }, 500); // 遅延時間
      }
    }
  });

  $(".js-section").each(function () {
    const sectionTop = $(this).offset().top;
    
    if (scroll > sectionTop - windowHeight) {
      const $fadeUpElements = $(this).find('.js-fadeUp');

      $fadeUpElements.each(function(index) {
        const fadePosition = $(this).offset().top;

        if(scroll > fadePosition - windowHeight) {
          const delay = index * 150;
          $(this).addClass("is-show").css("transition-delay", delay + "ms");
        }
      })
    }
  });

  const $textElement = $('.js-text-target');
  const textPosition = $textElement.offset().top + 100;

  if (scroll >  textPosition - windowHeight) {
    $textElement.addClass("is-blur-show");
  }

});


